/*
Template Name: Admin Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers
 *	3. Navigations
 *	4. Banners
 *	5. Footers
 *	6. app
 *	7. Widgets
 *	8. Custom Templates
 */
/*
Template Name: Admin Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*Theme Colors*/
/*bootstrap Color*/
/*Light colors*/
/*Normal Color*/
/*Extra Variable*/
/*
Template Name: Admin Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/**
 * Table Of Content
 *
 *  1. Globals
 *  2. Headers
 *  3. Navigations
 *  4. Banners
 *  5. Footers
 *  6. Posts
 *  7. Widgets
 *  8. Custom Templates
 */
/* @import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700"); */
/******************* 
Global Styles 
*******************/
/* @import url(../scss/icons/font-awesome/css/font-awesome.min.css);
@import url(../scss/icons/simple-line-icons/css/simple-line-icons.css);
@import url(../scss/icons/weather-icons/css/weather-icons.min.css);
@import url(../scss/icons/linea-icons/linea.css);
@import url(../scss/icons/themify-icons/themify-icons.css);
@import url(../scss/icons/flag-icon-css/flag-icon.min.css);
@import url(../scss/icons/material-design-iconic-font/css/materialdesignicons.min.css);
@import url(../css/spinners.css);
@import url(../css/animate.css); */

/*******************
List-style-none
******************/
ul.list-style-none {
  margin: 0px;
  padding: 0px; }
  ul.list-style-none li {
    list-style: none; }
    ul.list-style-none li a {
      color: #67757c;
      padding: 8px 0px;
      display: block;
      text-decoration: none; }
      ul.list-style-none li a:hover {
        color: #398bf7; }

/*
Template Name: Admin Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*******************
Main sidebar
******************/
.left-sidebar {
  position: absolute;
  width: 240px;
  height: 100%;
  top: 0px;
  z-index: 20;
  padding-top: 70px;
  background: #fff;
  -webkit-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08); }

.fix-sidebar .left-sidebar {
  position: fixed; }

/*******************
use profile section
******************/
.sidebar-nav .user-profile > a img {
  width: 30px;
  border-radius: 100%;
  margin-right: 10px; }

.sidebar-nav .user-profile > ul {
  padding-left: 40px; }

/*******************
sidebar navigation
******************/
.scroll-sidebar {
  height: calc(100% - 70px); }
  .scroll-sidebar.ps .ps__scrollbar-y-rail {
    left: 2px;
    right: auto;
    background: none;
    width: 6px;
    /* If using `left`, there shouldn't be a `right`. */ }

.collapse.in {
  display: block; }

.sidebar-nav {
  background: #fff;
  padding: 15px 0 0 0px; }
  .sidebar-nav ul {
    margin: 0px;
    padding: 0px; }
    .sidebar-nav ul li {
      list-style: none; }
      .sidebar-nav ul li a {
        color: #687384;
        padding: 8px 35px 8px 15px;
        display: block;
        font-size: 14px;
        font-weight: 400; }
        .sidebar-nav ul li a.active, .sidebar-nav ul li a:hover {
          color: #398bf7; }
          .sidebar-nav ul li a.active i, .sidebar-nav ul li a:hover i {
            color: #398bf7; }
        .sidebar-nav ul li a.active {
          font-weight: 500;
          color: #263238; }
      .sidebar-nav ul li ul {
        padding-left: 28px; }
        .sidebar-nav ul li ul li a {
          padding: 7px 35px 7px 15px; }
        .sidebar-nav ul li ul ul {
          padding-left: 15px; }
      .sidebar-nav ul li.nav-small-cap {
        font-size: 12px;
        margin-bottom: 0px;
        padding: 14px 14px 14px 20px;
        color: #263238;
        font-weight: 500; }
      .sidebar-nav ul li.nav-devider {
        height: 1px;
        background: rgba(120, 130, 140, 0.13);
        display: block;
        margin: 15px 0; }
  .sidebar-nav > ul > li > a i {
    width: 31px;
    font-size: 24px;
    display: inline-block;
    vertical-align: middle;
    color: #555f6d; }
  .sidebar-nav > ul > li > a .label {
    float: right;
    margin-top: 6px; }
  .sidebar-nav > ul > li > a.active {
    font-weight: 400;
    background: #ffffff;
    color: #26c6da; }
  .sidebar-nav > ul > li {
    margin-bottom: 5px; }
    .sidebar-nav > ul > li.active > a {
      color: #398bf7;
      font-weight: 500; }
      .sidebar-nav > ul > li.active > a i {
        color: #398bf7; }
  .sidebar-nav .waves-effect {
    transition: none;
    -webkit-transition: none;
    -o-transition: none; }

.sidebar-nav .has-arrow {
  position: relative; }
  .sidebar-nav .has-arrow::after {
    position: absolute;
    content: '';
    width: 7px;
    height: 7px;
    border-width: 1px 0 0 1px;
    border-style: solid;
    border-color: #687384;
    right: 1em;
    -webkit-transform: rotate(135deg) translate(0, -50%);
    -ms-transform: rotate(135deg) translate(0, -50%);
    -o-transform: rotate(135deg) translate(0, -50%);
    transform: rotate(135deg) translate(0, -50%);
    -webkit-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    top: 47%;
    -webkit-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    transition: all .3s ease-out; }

.sidebar-nav .active > .has-arrow::after,
.sidebar-nav li > .has-arrow.active::after,
.sidebar-nav .has-arrow[aria-expanded="true"]::after {
  -webkit-transform: rotate(-135deg) translate(0, -50%);
  -ms-transform: rotate(-135deg) translate(0, -50%);
  -o-transform: rotate(-135deg) translate(0, -50%);
  top: 45%;
  width: 7px;
  transform: rotate(-135deg) translate(0, -50%); }

/****************
When click on sidebar toggler and also for tablet
*****************/
@media (min-width: 768px) {
  .mini-sidebar .sidebar-nav #sidebarnav li {
    position: relative; }
  .mini-sidebar .sidebar-nav #sidebarnav > li > ul {
    position: absolute;
    left: 70px;
    top: 46px;
    width: 220px;
    padding-bottom: 10px;
    z-index: 1001;
    background: #edf0f5;
    display: none;
    padding-left: 1px; }
  .mini-sidebar .user-profile .profile-img {
    width: 45px; }
    .mini-sidebar .user-profile .profile-img .setpos {
      top: -35px; }
  .mini-sidebar.fix-sidebar .left-sidebar {
    position: fixed; }
  .mini-sidebar .sidebar-nav #sidebarnav > li:hover > ul {
    height: auto !important;
    overflow: auto; }
  .mini-sidebar .sidebar-nav #sidebarnav > li:hover > ul,
  .mini-sidebar .sidebar-nav #sidebarnav > li:hover > ul.collapse {
    display: block; }
  .mini-sidebar .sidebar-nav #sidebarnav > li > a.has-arrow:after {
    display: none; }
  .mini-sidebar .left-sidebar {
    width: 70px; }
  .mini-sidebar .sidebar-nav #sidebarnav .user-profile > a {
    padding: 12px 20px; }
  .mini-sidebar .scroll-sidebar {
    padding-bottom: 0px;
    position: absolute; }
  .mini-sidebar .hide-menu,
  .mini-sidebar .nav-small-cap,
  .mini-sidebar .sidebar-footer,
  .mini-sidebar .user-profile .profile-text,
  .mini-sidebar > .label {
    display: none; }
  .mini-sidebar .nav-devider {
    width: 70px; }
  .mini-sidebar .sidebar-nav {
    background: transparent; }
  .mini-sidebar .sidebar-nav #sidebarnav > li > a {
    padding: 12px 24px;
    width: 70px; }
  .mini-sidebar .sidebar-nav #sidebarnav > li:hover > a {
    width: 290px;
    background: #edf0f5; }
    .mini-sidebar .sidebar-nav #sidebarnav > li:hover > a .hide-menu {
      display: inline; }
    .mini-sidebar .sidebar-nav #sidebarnav > li:hover > a .label {
      display: none; } }

@media (max-width: 767px) {
  .mini-sidebar .left-sidebar {
    position: fixed; }
  .mini-sidebar .left-sidebar,
  .mini-sidebar .sidebar-footer {
    left: -240px; }
  .mini-sidebar.show-sidebar .left-sidebar,
  .mini-sidebar.show-sidebar .sidebar-footer {
    left: 0px; } }