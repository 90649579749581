.modal.modal-fullscreen {
  /* background-color: transparent; */
  border: unset;
  box-shadow: unset;
  width: 100vw;
  max-width: unset;
  height: 100vh;
  

}

.modal.modal-fullscreen .modal-dialog {
  margin: 0;
  padding: 0;
  max-width: unset;
  transform: unset;
}

.modal.modal-fullscreen .modal-dialog .modal-content {
  background-color: transparent;
  border: unset;
  box-shadow: unset;
  height: 100vh;
}

.autocomplete{
    background-color: transparent !important;
    border: none !important;
    border-radius: 0 !important;
    height: 3rem !important;
    width: 100% !important;
    font-size: 16px !important;
    margin: 0 0 8px 0 !important;
    padding: 0 !important;
    line-height: 1.1 !important;
    box-sizing: content-box !important;
    border-bottom: 0 !important;
    box-shadow: unset !important;
    background-image: linear-gradient(to top, #ff9800 2px, rgba(156, 39, 176, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px) !important;
    background: no-repeat center bottom, center calc(100% - 1px) !important;
    background-size: 0 100%, 100% 100%;
}