@font-face{
font-family: 'Playfair';
src: url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap") format("truetype");
}

img.brand {
  max-height: 40px;
}

.link:hover,
.link:focus{
  color: rgba(0, 0, 0, 0);
  background-color: rgba(0,0,0,0) !important;
}

.navbar {
  position: relative;
  box-shadow: 0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15) !important;
  /* border-right: none; */
}

.navbar::before,
.navbar::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
}

.navbar::before {
  background-image: url(/assets/img/navbar1.jpg);
}

.navbar::after {
  /* background: #fff;
  opacity: 0.95; */
  background-image: linear-gradient(to left, rgb(255 255 255 / 0.58), rgb(255 255 255 / 0.85));
}

.navbar > div {
  z-index: 1;
}

.header-font{
  font-family: 'Cinzel', serif !important;
  /* font-family: 'Playfair Display', serif !important; */
  /* font-style: bold; */
  /* font-family: 'Libre Baskerville', serif !important; */
}