body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body,
html,
#root,
.App,
.dashboard {
  /* height: 100%; */
  overflow-x: hidden;
}

#root {
  width: 100vw;
}

.navbar .dropdown-menu.right{
  transform: none !important;
  left: unset !important;
  top: 40px !important;
  right: 0;
}

.invalid {
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-control {
  border-color: #dc3545 !important;
}

.invalid-label {
  max-height: 50px;
  color: #dc3545 !important;
}

.invalid-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(255,0,0,.25) !important;
}

.md-invalid-control {
  border-bottom: 1px solid #dc3545 !important;
  box-shadow: 0 1px 0 0 #dc3545 !important;
}

.md-invalid-control ~ label {
  max-height: 50px;
  color: #dc3545 !important;
}


.md-form.form-group {
  margin-bottom: 0;
}

.loading {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
      -webkit-transform: rotate(0deg);
  }
  to {
      -webkit-transform: rotate(359deg);
  }
}

.pagination-select .dropdown-content,
.pagination-select .dropdown-content li,
.pagination-select .dropdown-content span {
  color: inherit !important;
}

.pagination-select input.select-dropdown {
  border-bottom: 0 !important;
  margin-bottom: 0 !important
}


.pagination li.active.primary {
  background-color: #ff9800;
}

.form-group .prefix {
  position: absolute;
  width: 3rem;
  font-size: 2rem;
  transition: color .2s;
  top: .5rem;
}

.form-group .prefix ~ input, .form-group .prefix ~ textarea, .form-group .prefix ~ label, .form-group .prefix ~ .validate ~ label, .form-group .prefix ~ .helper-text, .form-group .prefix ~ .autocomplete-content {
  margin-left: 3rem !important;
  width: 92% !important;
  width: calc(100% - 3rem) !important;
}

.form-control:focus + label{
  color: #ff9800 !important;
}

.has-danger .form-control:focus + label{
  color: #dc3545 !important;
}

.form-group .prefix.active {
  color: #ff9800 !important;
}


.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
  animation: spinner-grow .75s linear infinite;
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
      opacity: 1;
  }
}

.disabled {
  pointer-events: none !important;
}

button:focus {
  /* background-color: initial !important; */
}

.float-left {
  float: left !important;
}
@media only screen and (min-width: 601px){
  nav, nav .nav-wrapper i, nav a.sidenav-trigger, nav a.sidenav-trigger i {
    height: auto !important;
    line-height: inherit !important;
  }
}

nav {
  color: inherit !important;
  background-color: transparent !important;
  width: initial !important;
  height: auto !important;
  line-height: initial !important;
}
.z-depth-1, nav, .card-panel, .card, .toast, .btn, .btn-large, .btn-small, .btn-floating, .dropdown-content, .collapsible, .sidenav {
  box-shadow: unset !important;
}

.modal {
  /* display: none; */
  /* position: fixed; */
  /* left: 0; */
  /* right: 0; */
  background-color: transparent !important;
  /* padding: 0; */
  max-height: unset !important;
  box-shadow: unset !important;
  /* width: 55%; */
  /* margin: auto; */
  /* overflow-y: auto; */
  /* border-radius: 2px; */
  /* will-change: top, opacity; */
}

.datepicker-date-display,
.datepicker-table td.is-selected {
  background-color: #ff9800 !important;
}

.datepicker-table td.is-today, .datepicker-cancel, .datepicker-done {
  color: #ff9800 !important;
}
.datepicker-table td.is-selected {
  color: #fff !important;
}


#clases{
 color:  #ff9800;
 background:#fff;
}

[type="checkbox"]:checked+span:not(.lever):before {
  border-right: 2px solid #ff9800 !important;
  border-bottom: 2px solid #ff9800 !important;
  
}

.loading-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1024;
  background-color: rgba(0, 0, 0, 0.1);
  min-height: 70vh;
}

.loading-container .spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.hi {
  top: -40px !important;
  height: calc(100% + 40px);
}

#toast-container {
  left: 20px !important;
  bottom: 10px !important;
  /* right: 20px !important; */
  top: unset !important;
  width: 250px !important;
}

#slim {
  border-radius: 50%;
  background-color: #ff9800;
  z-index: 5;
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(50%);
  right: 0;
  color: #fff;
}

#slim i {
  font-size: 1.5rem;
}

.slim > .logo > div {
  margin-left: 0 !important;
  margin-right: 0 !important;
} 

@media (min-width: 991.02px) {
  .spinner {
    margin-left: 92.5px;
  }

  .sidebar.slim ~ .main-panel .spinner {
    margin-left: 45px;
  }

  .sidebar.slim ~ .main-panel {
    width: calc(100% - 65px);
  }

  .sidebar.slim {
    width: 65px;
  }

  .sidebar.slim .sidebar-wrapper .nav .nav-item p {
    z-index: -1;
    opacity: 0;
  }

  #slim {
    display: block;
  }
}


.sidebar {
  transition: width 100ms;
  /* overflow-y: scroll; */
  /* overflow-x: hidden; */
}

.sidebar .sidebar-wrapper {
  width: 100%;
  height: auto;
}
.main-panel {
  transition: width 50ms;
}

table tbody tr .form-control-outline {
  margin: 0 !important;
  padding: 2px 4px !important;
  outline: initial !important;
  border: 1px solid #f2f2f2 !important;
  /* border-radius: 5px !important; */
  line-height: inherit !important;
  height: unset !important;
  width: 35px !important;
  text-align: center;
  box-shadow: unset !important;
  /* color: -internal-light-dark-color(black, white); */
  font-size: 100% !important;
}

table tbody tr input::-webkit-outer-spin-button,
table tbody tr input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
table tbody tr input[type=number] {
  -moz-appearance: textfield;
}

.select-wrapper input.select-dropdown:disabled {
  color: #495057 !important;
}


input:not([value=""]) ~ label,
input[type="date"] ~ label {
  top: -1rem !important;
  left: 0 !important;
  font-size: 0.6875rem !important;
}

@media (max-width: 600px) {
  .container-fluid  {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .content {
    padding: 30px 0px !important;
  }
}

.login .spinner {
  margin-left: 0;
  margin-top: -4px;
}

.login .spinner .preloader-wrapper.small {
  width: 20px;
  height: 20px;
}

.square {
  position: relative;
}

.square .square-content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.square:before {
  content: " ";
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  padding-bottom: 100%;
}